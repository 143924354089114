<template>
  <div class="line-OAuth d-flex flex-row">
    <v-card class="ma-auto ems-opacity-50-bg" width="360" flat elevation="2">
      <v-card-text>
        <p>
          連結LINE帳號時，請勿任意關閉視窗，接收通知的聊天室請選擇<span
            class="black--text font-weight-bold"
            >【透過1對1聊天接收LINE Notify的通知】</span
          >，點選<span class="black--text font-weight-bold">【同意並連動】</span
          >。
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="my-2 mx-auto white--text"
          color="#00B900"
          @click="oAuth2()"
        >
          連結LINE帳號
        </v-btn>
        <v-btn class="my-2 mx-auto" @click="$router.go(-1)"> 取消 </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import settings from '@/settings'

export default {
  mixins: [],
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters({
      user: 'user/userData'
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {
    if (!this.user) {
      this.$router.push({ path: '/login' })
    }
  },
  destroyed() {},
  methods: {
    oAuth2() {
      var host = window.location.protocol + '//' + window.location.host
      const redirectUri = `${host}${settings.publicPath}/line/OAuth/callback`
      const clientId = settings.LINE.client_id
      const URL =
        'https://notify-bot.line.me/oauth/authorize?' +
        'response_type=code' +
        `&client_id=${clientId}` +
        `&redirect_uri=${redirectUri}` +
        '&scope=notify' +
        '&state=NO_STATE'
      console.log(URL)
      window.location.href = URL
    }
  }
}
</script>

<style scoped>
.line-OAuth {
  height: 100vh;
}
</style>
